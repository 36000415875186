import React from "react";
import { navigate, Link } from "@reach/router";
import { motion } from "framer-motion";
import Select from "react-select";
import { Switch } from '@headlessui/react'

import {DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  RefreshIcon,
  LockClosedIcon,
  PlusIcon,
  MailIcon,
  WrenchIcon,
  PencilIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";

import { ReactComponent as ChooseIcon } from "../assets/images/icons8-choose.svg";
import { ReactComponent as ProductIcon } from "../assets/images/icons8-product.svg";
import { ReactComponent as BuildIcon } from "../assets/images/icons8-laying-bricks.svg";

import Config from "../config";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import LineItem from "../components/LineItem";
import ProductLineItem from "../components/ProductLineItem";
import LookUpLineItem from "../components/LookUpLineItem";
import Preview from "../components/Preview";
import OptionField from "../components/fields/OptionField";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";

import Filters from "../components/Filters";
import ProductSearch from "../components/fields/ProductSearch";

/*

TO DO
[x] - drag and drop hs_order property
[] - automatically show properties that have been added in other lines?
[] - are you sure?
[] - save or alert when click to another line item "edit" button

*/

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      message: false,
      showPreview: false,
      confirmDelete: false,
      active: false,
      isMatch: null,
      percentage: 0.5,
      deals: [],
      trigger: {},
      selected: [],
      lineItems: [],
      filters: [],
      calculation: "subtotal",
    };
    this._isMounted = false;
  }
  componentDidMount = async () => {
    document.title = "Template";
    this._isMounted = true;
    this._isMounted && (await this.getStatus());
    this._isMounted && (await this.getAccountStatus());
    let user = await Session.getUserData();
    this.setState({ user: user, pageLoading: false });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.success && this.state.success) {
      setTimeout(() => {
        this.setState({ success: false });
      }, 3000); // Close success messages after 3 seconds
    }
  }
  generateRandomKey() {
    return Math.random().toString(36).substring(7);
  }

  async updateTrigger(type, selectedOption) {
    let trigger = {};
    if (type === "dealstage") {
      trigger[type] = selectedOption?.value;
    } else {
      trigger[type] = {};
    }
    await this.setState({ trigger });
  }

  async chooseLineItemType(type) {
    await this.setState({ addLineItem: type });
    if (type !== "product") {
      this.addLineItem(type);
    }
  }

  async addLineItem(type,product = false) {
    let lineItems = [...this.state.lineItems];
    let id = this.generateRandomKey();

    // find the highest value of hs_position_on_quote within lineItems and add one, or lineItems.length if none
    let positionOnQuote = lineItems.reduce((acc, item) => {
      return item.schema.hs_position_on_quote?.value > acc
        ? item.schema.hs_position_on_quote?.value
        : acc;
    }, 0);
    positionOnQuote = Number(positionOnQuote) + 1;

    let schema = {
      quantity: {
        value: 1,
        type: false,
        hubspotDefined: true,
      },
      hs_position_on_quote: {
        value: positionOnQuote,
        type: "number",
        hubspotDefined: true,
      },
    };

    if (type === "blank") {
      schema = {
        ...schema,
        ...{
          name: {
            value: false,
            type: false,
            hubspotDefined: true,
          },
          price: {
            value: false,
            type: false,
            hubspotDefined: true,
          },
          hs_sku: {
            value: false,
            type: false,
            hubspotDefined: true,
          },
        },
      };
    }
    lineItems.push({
      id,
      type,
      properties: {},
      schema,
      product,
      lookUpProducts: [],
    });
    await this.setState({ lineItems: lineItems, addProductLineItem: false, addLineItem: false });
    this.updateLineItem(id, "isEditing", true);
  }

  async removeLineItem(id) {
    let lineItems = [...this.state.lineItems];
    let newLineItems = lineItems.filter((item) => item.id !== id);
    await this.setState({ lineItems: newLineItems });
    await this.saveTemplate();
  }

  async getStatus() {
    try {
      let requestUrl = Config.api.root + "/template/get/" + this.props.templateId;
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this._isMounted &&
        this.setState({
          ...response,
        });
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      this._isMounted &&
        this.setState({
          error: true,
          errorDetails: { title: "Could not fetch template", message: error },
          loading: false,
        });
    }
  }

  async getAccountStatus() {
    try {
      const requestUrl = Config.api.root + "/admin/user/account";

      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this._isMounted &&
        this.setState({
          account: {
            runCount: Number(response.runCount),
            runLimit: Number(response.runLimit),
            templateLimit: Number(response.templateLimit),
            features: response.features,
          },
        });
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      this._isMounted &&
        this.setState({
          error: true,
          errorDetails: { title: "Could not get account status", message: error },
          loading: false,
        });
    }
  }

  async getPreview(dealId) {
    try {
      if (!dealId) {
        this.setState({ updatedLines: false });
        return false;
      }
      this.setState({ loadingPreview: true });

      let requestUrl = `${Config.api.root}/deals/preview/${dealId}/${this.props.templateId}`;
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      
      this._isMounted &&
        this.setState({
          dealId,
          ...response,
          loadingPreview: false,
        });
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      this._isMounted && this.setState({ loadingPreview: false });
    }
  }

  async runTemplate(dealId)  {
    let requestUrl = `${Config.api.root}/deals/run/${dealId}/${this.props.templateId}`;
    try {
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this.setState({
        runSuccess: true,
        runDetails: response.details,
      });
    } catch (error) {
      this.setState({
        runSuccess: false,
        runDetails: { error },
      });
    }
  }

  async saveTemplate(e) {
    e && e.preventDefault();

    try {
      let validation = await this.validationCheck();
      if (!validation) {
        return false;
      }
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/template/save";
      let options = {
        method: "POST",
        body: JSON.stringify({
          template: {
            id: this.props.templateId,
            trigger: this.state.trigger,
            filters: this.state.filters,
            name: this.state.name,
            active: this.state.active,
            calculation: this.state.calculation,
            replaceOtherTemplateItems: this.state.replaceOtherTemplateItems,
            replaceNonLPItems: this.state.replaceNonLPItems,
            filterZeroPriceQtyItems: this.state.filterZeroPriceQtyItems,
            replaceOwnItems: this.state.replaceOwnItems,
          },
          lineItems: this.state.lineItems,
        }),
      };
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();

      if (response.error) {
        throw response.error;
      }
      this.setState({
        success: response?.template ? true : false,
        message: response?.message ? response.message : false,
        active: response.template.active,
        lineItems: response.lineItems,
        loading: false,
      });
      this.getPreview();
    } catch (error) {
      console.error(error);
      this.setState({
        error: true,
        errorDetails: { title: "Could not save template", message: error },
        loading: false,
      });
    }
  }

  async deleteTemplate(e) {
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/template/delete/" + this.props.templateId;
      let options = {
        method: "DELETE",
      };
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this.setState({ confirmDelete: false });
      navigate("/");
    } catch (error) {
      this.setState({
        error: true,
        errorDetails: { title: "Could not delete template", message: error },
        loading: false,
      });
    }
  }

  async updateLineItem(id, param, data) {
    const lineItems = this.state.lineItems;
    const newLineItems = lineItems.map((item) => {
      return {
        ...item,
        [param]: item?.id === id ? data : item[param],
      };
    });
    this.setState({ lineItems: newLineItems });
  }

  async validationCheck() {
    let lineItems = [...this.state.lineItems];
    let validation = true;
    let errorDetails = {
      title: "Validation Error",
      message: "Please check your line items for errors.",
    };
    // check for empty values on required fields: name, price, quantity
    lineItems.forEach((item) => {
      if (!item?.product && !item.type === "lookup") {
        if (!item.schema.name.value || !item.schema.price.value || !item.schema.quantity.value) {
          validation = false;
          errorDetails.message =
            "Required fields not completed. Each line item needs a name, quantity and price (even if 0)";
        }
      }
    });

    if (validation) {
      return true;
    } else {
      this.setState({ error: true, errorDetails });
      return false;
    }
  }

  async onDragEnd(result) {
    const newLineItems = [...this.state.lineItems];
    const { source, destination } = result;

    if(result.type === "product") {
      // look through newLine Items and find the item with id source.droppableId then get the products from item.lookUpProducts and reorder based on destination.index, matching the just moved product by draggableId === product.id
      let item = newLineItems.find((item) => item.id.toString() === source.droppableId);
      let products = item.lookUpProducts;
      let [removed] = products.splice(source.index, 1);
      products.splice(destination.index, 0, removed);

      products = await Promise.all(products.map(async (product, index) => {
        product.displayOrder = index;
        return product;
      }));
      item.lookUpProducts = products;
    } else {
      const [removed] = newLineItems.splice(source.index, 1);
      newLineItems.splice(destination.index, 0, removed);

      newLineItems.map(async (item, index) => {
        item.schema.hs_position_on_quote = {
          value: index,
          type: "number",
        };
      });
    }

    this.setState({ lineItems: newLineItems });
  }

  canAccessWorkflows = () => {
    return this.state?.account?.features?.workflows;
  }

  render() {
    const accessWorkflows = this.canAccessWorkflows();

    const stageOptions = () => {
      if (this.state.hubspotStages) {
        let stages = [];
        for (let pipeline of this.state.hubspotStages) {
          let pipelineStages = pipeline.stages.map((s) => {
            return {
              label: `${pipeline.label} - ${s.label}`,
              value: s.id,
            };
          });
          stages = [...stages, ...pipelineStages];
        }

        return stages;
      }
    };

    let sortedLines =
      this.state.lineItems &&
      this.state.lineItems.sort((a, b) => {
        return a.schema?.hs_position_on_quote?.value - b.schema?.hs_position_on_quote?.value;
      });

    const lineItemContainer =
      sortedLines &&
      sortedLines.map((item, itemIndex) => {
        return (
          <Draggable key={item.id} draggableId={item.id.toString()} index={itemIndex}>
            {(provided, snapshot) => {
              const style = {
                // borderTopColor: snapshot.isDragging ? "#e5e7eb " : "",
                // borderTopWidth: snapshot.isDragging ? "1px" : "",
                ...provided.draggableProps.style,
              };

              let LineItemContainer;
              if(item.type === "product" || item?.product) {
                LineItemContainer = ProductLineItem;
              } else if(item.type === "lookup") {
                LineItemContainer = LookUpLineItem;
              } else {
                LineItemContainer = LineItem;
              }

              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={style}
                >
                  <LineItemContainer
                    {...item}
                    products={this.state.products}
                    dealPropertyDefinitions={this.state.dealProperties}
                    lineItemPropertyDefinitions={this.state.lineItemProperties}
                    dealStages={this.state.hubspotStages}
                    saveTemplate={this.saveTemplate.bind(this)}
                    removeLineItem={this.removeLineItem.bind(this)}
                    updateLineItem={this.updateLineItem.bind(this)}
                    isDragging={snapshot.isDragging}
                    user={this.state.user}
                    account={this.state.account}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      });

    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }

    const calculationOptions = [
      { value: "none", label: "None - do not update" },
      { value: "subtotal", label: "Subtotal" },
      { value: "tcv", label: "TCV - Total Contract Value" },
      { value: "acv", label: "ACV - Annual Contract Value" },
      { value: "mrr", label: "MRR" },
      { value: "arr", label: "ARR" }
    ];

    return (
      <div className="min-h-screen">
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <>
            <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-10 lg:mx-auto">
              <div className="flex-row flex-1 w-full">
                <header className="flex flex-row gap-5 pb-5 border-b mt-36 border-tone-500">
                  <div className="flex flex-col items-baseline flex-grow gap-1">
                    <div className="flex flex-row flex-grow w-full gap-5 items-top">
                      <h1
                        className={
                          this.state.editName
                            ? "border-b border-tone-500 focus:border-dark focus:outline-none"
                            : ""
                        }
                        contentEditable={this.state?.editName}
                        onBlur={(e) => this.setState({ name: e.currentTarget.textContent })}
                      >
                        {this.state.name}
                      </h1>
                      <span className="">
                        <button
                          className="button-icon"
                          onClick={(e) => this.setState({ editName: !this.state.editName })}
                        >
                          <WrenchIcon className="w-3 h-3" />
                        </button>
                      </span>
                    </div>
                    <div className="">
                      <Link to="/" className="button-text">
                        <ArrowLeftIcon className="w-3 h-3" />
                        Back to Templates
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col items-end gap-5">
                    <div className="flex flex-row items-start gap-5">
                      <button
                        className="button"
                        onClick={(e) => this.setState({ showPreview: !this.state.showPreview })}
                      >
                        Preview
                      </button>
                      <button className="button-primary" onClick={(e) => this.saveTemplate(e)}>
                        Save
                      </button>
                    </div>

                    <Switch.Group as="div" className="flex items-center">
                      <Switch.Label as="span" className="mr-3 text-sm">
                        <span className="font-medium">
                          {this.state.active ? "Active" : "Inactive"}
                        </span>
                      </Switch.Label>
                      <Switch
                        checked={this.state.active}
                        onChange={(e) => this.setState({ active: !this.state.active })}
                        className={classNames(
                          this.state.active ? "bg-primary" : "bg-tone-400",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.active ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        >
                          &nbsp;
                        </span>
                      </Switch>
                    </Switch.Group>
                  </div>
                </header>

                <section className="relative flex flex-col gap-3 py-8 border-b border-tone-500 group/section">
                  <div className="absolute hidden px-5 py-2 ml-16 transition-opacity duration-200 ease-in lg:block opacity-60 border-l-10 top-10 border-tone-500 left-full w-96 group-hover/section:opacity-100 text-tone-900">
                    <h3 className="flex items-center gap-2 mt-0">
                      <QuestionMarkCircleIcon className="w-7 h-7 -ml-3.5 " />
                      Triggers
                    </h3>
                    <p className="mt-5 text-sm">
                      Triggers are the events that will cause your line items to be updated. This
                      can happen multiple times and you won't get any duplicate line items.
                    </p>
                    <p className="mt-5 text-sm">
                      {" "}
                      Each time a trigger is activated, and the filters match, the line items will
                      be updated and this will count as a "run" of LinePilot.
                    </p>
                    <p className="mt-5 text-sm">
                      <a
                        href="https://www.linepilot.co/articles/using-triggers-and-workflows-create-line-items"
                        className="flex items-center gap-2"
                      >
                        Find out more about Triggers
                        <ArrowRightIcon className="w-4 h-4" />
                      </a>
                    </p>
                  </div>
                  <OptionField
                    value={this.state?.trigger && Object.keys(this.state.trigger)[0]}
                    label="Trigger"
                    id="trigger"
                    options={[
                      {
                        value: "dealcreate",
                        label: "Deal Create",
                        description: "<p>Run when a deal is created</p>",
                        proMessage: false,
                        upgradeMessage: false,
                      },
                      {
                        value: "dealstage",
                        label: "Deal Stage Change",
                        description: "<p>Run when a deal moves to a specific stage</p>",
                        proMessage: false,
                        upgradeMessage: false,
                      },
                      // {
                      //   value: "activeproperty",
                      //   label: "Property Change",
                      //   description:
                      //     '<p>Update when a property from your template changes *</p><p class="mt-2 text-tone-700 text-xs">* Only updates when Standard HubSpot deal properties change</p>',
                      // },
                      {
                        value: "workflow",
                        label: "Workflow",
                        disabled: !accessWorkflows,
                        description: "<p>Trigger from a workflow action inside HubSpot</p>",
                        proMessage: ["trial", "partner"].includes(this.state.user.plan),
                        upgradeMessage: ["free", "starter"].includes(this.state.user.plan),
                      },
                    ]}
                    onChange={(e) => this.updateTrigger(e)}
                  />

                  {Object.keys(this.state.trigger)[0] === "dealstage" && (
                    <div className="flex flex-row flex-1 max-w-xl gap-5 my-2">
                      <div className="flex items-center justify-end">
                        <span className="mr-5 font-semibold">Deal Stage</span>
                        <span>equals</span>
                      </div>
                      <div className="flex-1">
                        <Select
                          className="text-sm "
                          options={stageOptions()}
                          isClearable="true"
                          defaultValue={stageOptions().find(
                            (o) => o.value === this.state.trigger?.dealstage
                          )}
                          theme={Config.selectTheme}
                          onChange={(o) => this.updateTrigger("dealstage", o)}
                        />
                      </div>
                    </div>
                  )}
                </section>

                <section className="relative py-5 border-b border-tone-500 group/section">
                  <div className="absolute hidden px-5 py-2 ml-16 transition-opacity duration-200 ease-in lg:block opacity-60 border-l-10 top-5 border-tone-500 left-full w-96 group-hover/section:opacity-100 text-tone-900">
                    <h3 className="flex items-center gap-2 mt-0">
                      <QuestionMarkCircleIcon className="w-7 h-7 -ml-3.5 " />
                      Filters
                    </h3>
                    <p className="mt-5 text-sm">
                      Use filters to narrow down the deals that will trigger your line items. We
                      strongly recommend using filters if you have more than one template.
                    </p>
                  </div>
                  <div className="flex-1">
                    <h3>Filter</h3>
                  </div>
                  {this.state.dealProperties && (
                    <Filters
                      dealPropertyDefinitions={this.state.dealProperties}
                      filters={this.state.filters || []}
                      dealStages={this.state.hubspotStages}
                      updateFilters={(filters) => this.setState({ filters })}
                    />
                  )}
                </section>

                <section className="relative pb-5 my-10 border-b group/section border-tone-500">
                  <div className="absolute hidden px-5 py-2 ml-16 transition-opacity duration-200 ease-in lg:block opacity-60 border-l-10 top-10 border-tone-500 left-full w-96 group-hover/section:opacity-100 text-tone-900">
                    <h3 className="flex items-center gap-2 mt-0">
                      <QuestionMarkCircleIcon className="w-7 h-7 -ml-3.5 " />
                      Line Items
                    </h3>
                    <p className="mt-5 text-sm">
                      Choose which Line items are added or updated on your deal. If you have
                      existing line items on your deal when the template is applied, these will be
                      ignored.
                    </p>
                    <p className="mt-5 text-sm">
                      Drag and drop line items to change the order they appear on the deal.
                    </p>
                    <p className="mt-5 text-sm">
                      You can combine deal properties or use deal properties alongside text, by
                      using the brackets like this &#123;&#123;property_internal_name&#125;&#125; in
                      the value field.
                    </p>
                    <div>
                      {/* list all property values with the internal name
                    {this.state.dealProperties &&
                      this.state.dealProperties.map((property) => {

                        return (
                          <tr key={property.name} className="">
                              <td className="flex flex-row items-center text-sm">
                                {property.label}
                              </td>

                              <td className="text-xs text-toneDark">&#123;&#123;{property.name}&#125;&#125;</td>

                          </tr>
                        );
                      }
                    )}
                    </table> */}
                    </div>
                    <p className="mt-5 text-sm">
                      <a
                        href="https://www.linepilot.co/articles/building-line-items-using-deal-properties"
                        className="flex items-center gap-2"
                      >
                        Find out more about Properties
                        <ArrowRightIcon className="w-4 h-4" />
                      </a>
                    </p>
                  </div>

                  <h3>Line Items</h3>
                  <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <Droppable droppableId={"1"}>
                      {(provided, snapshot) => (
                        <div className="w-full my-5" ref={provided.innerRef}>
                          <div className="relative">
                            {lineItemContainer}

                            {provided.placeholder}

                            <OptionField
                              value={this.state.addLineItem}
                              id="addLineItem"
                              options={[
                                {
                                  value: "product",
                                  label: "Add Single Product",
                                  icon: <ProductIcon className="w-10 h-10 fill-primary" />,
                                  description:
                                    "<p>Choose a product from your HubSpot product library</p>",
                                },

                                {
                                  value: "lookup",
                                  label: "Add Product Selector",
                                  icon: <ChooseIcon className="w-10 h-10 fill-primary" />,
                                  description: "<p>Let users choose a product from a list</p>",
                                },
                                {
                                  value: "blank",
                                  label: "Add Custom Item",
                                  icon: <BuildIcon className="w-10 h-10 fill-primary" />,
                                  description:
                                    "<p>Add a new blank line item and add properties from scratch</p>",
                                },
                              ]}
                              onChange={(e) => this.chooseLineItemType(e)}
                            />

                            {this.state.addLineItem === "product" ? (
                              <div className="mt-4 ml-4">
                                <ProductSearch
                                  value={this.state.addProductLineItem}
                                  onChange={(o) => {
                                    this.setState({ addProductLineItem: o.id });
                                    o?.id && this.addLineItem("product", o.id);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </section>
                <section className="my-10 pb-96">
                  <button
                    className="flex items-center w-full gap-2 text-left"
                    onClick={(e) =>
                      this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions })
                    }
                  >
                    <ChevronDownIcon
                      className={classNames(
                        "w-4 h-4 ml-1",
                        this.state.showAdvancedOptions ? "transform " : "transform -rotate-90"
                      )}
                    />
                    <h3>Advanced Options </h3>
                  </button>

                  <div
                    className={classNames(
                      this.state.showAdvancedOptions ? "block" : "hidden",
                      "p-5 flex gap-3 flex-col"
                    )}
                  >
                    <div className="flex flex-row items-center gap-5 text-sm">
                      <label className="font-normal">Deal Total Calculation</label>
                      <Select
                        options={calculationOptions}
                        defaultValue={calculationOptions.find(
                          (o) => o.value === this.state.calculation
                        )}
                        onChange={(e) => this.setState({ calculation: e.value })}
                        theme={Config.selectTheme}
                      />
                    </div>
                    <Switch.Group as="div" className="flex items-center gap-3">
                      <Switch
                        checked={this.state.replaceOtherTemplateItems}
                        onChange={(e) =>
                          this.setState({
                            replaceOtherTemplateItems: !this.state.replaceOtherTemplateItems,
                          })
                        }
                        className={classNames(
                          this.state.replaceOtherTemplateItems ? "bg-primary" : "bg-tone-400",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.replaceOtherTemplateItems
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        >
                          &nbsp;
                        </span>
                      </Switch>

                      <Switch.Label as="span" className="text-sm">
                        Replace line items created by other LinePilot templates
                      </Switch.Label>
                    </Switch.Group>
                    <Switch.Group as="div" className="flex items-center gap-3">
                      <Switch
                        checked={this.state.replaceNonLPItems}
                        onChange={(e) =>
                          this.setState({ replaceNonLPItems: !this.state.replaceNonLPItems })
                        }
                        className={classNames(
                          this.state.replaceNonLPItems ? "bg-primary" : "bg-tone-400",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.replaceNonLPItems ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        >
                          &nbsp;
                        </span>
                      </Switch>
                      <Switch.Label as="span" className="text-sm">
                        Replace line items created outside LinePilot
                      </Switch.Label>
                    </Switch.Group>
                    <Switch.Group as="div" className="flex items-center gap-3">
                      <Switch
                        checked={this.state.filterZeroPriceQtyItems}
                        onChange={(e) =>
                          this.setState({
                            filterZeroPriceQtyItems: !this.state.filterZeroPriceQtyItems,
                          })
                        }
                        className={classNames(
                          this.state.filterZeroPriceQtyItems ? "bg-primary" : "bg-tone-400",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.filterZeroPriceQtyItems ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        >
                          &nbsp;
                        </span>
                      </Switch>
                      <Switch.Label as="span" className="text-sm">
                        Filter line items with a quantity or price of 0
                      </Switch.Label>
                    </Switch.Group>

                    <Switch.Group as="div" className="flex items-center gap-3">
                      <Switch
                        checked={this.state.replaceOwnItems}
                        onChange={(e) =>
                          this.setState({
                            replaceOwnItems: !this.state.replaceOwnItems,
                          })
                        }
                        className={classNames(
                          this.state.replaceOwnItems ? "bg-primary" : "bg-tone-400",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            this.state.replaceOwnItems ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        >
                          &nbsp;
                        </span>
                      </Switch>
                      <Switch.Label as="span" className="text-sm">
                        Replace previous line items created by this template
                      </Switch.Label>
                    </Switch.Group>
                    <button
                      className="-ml-2 text-red-600 button-text"
                      onClick={(e) => this.setState({ confirmDelete: true })}
                    >
                      <XMarkIcon className="w-3 h-3" />
                      Delete Template
                    </button>
                  </div>
                </section>
              </div>
              <div className="hidden lg:flex lg:basis-96">{/* Help Content here */}</div>
            </div>

            <Preview
              open={this.state.showPreview}
              setClose={(e) => this.setState({ showPreview: false })}
              updatedLines={this.state.updatedLines}
              isMatch={this.state.isMatch}
              updatedCount={this.state.updatedCount}
              createdCount={this.state.createdCount}
              getPreview={this.getPreview.bind(this)}
              loading={this.state.loadingPreview}
              dealId={this.state.dealId}
              hubspot={this.state.hubspotPortal}
              currency={this.state.previewCurrency}
              lineItemPropertyDefinitions={this.state.lineItemProperties}
              runTemplate={this.runTemplate.bind(this)}
            />

            <Message open={this.state.loading} type="loading" title="Saving template..." />
            <Message
              open={this.state.success}
              type="success"
              title="Template saved"
              setClose={() => this.setState({ success: false })}
            />
            <Modal
              open={this.state?.message}
              type="upgrade"
              title="Upgrade Required"
              confirmAction={() => navigate("/billing")}
              confirmText="View Plans"
              buttonText="Continue"
              message={this.state?.message}
              setClose={() => this.setState({ message: false })}
            />
            <Modal
              open={this.state?.error}
              type="error"
              title={this.state.errorDetails.title}
              message={this.state.errorDetails.message}
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />
            <Modal
              open={this.state?.confirmDelete}
              type="confirm"
              title="Delete Template"
              message="Are you sure you want to delete this template?"
              confirmText="Delete"
              confirmAction={() => this.deleteTemplate()}
              setClose={() => this.setState({ confirmDelete: false })}
            />
          </>
        )}
      </div>
    );
  }
}

export default Template;